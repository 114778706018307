<template>
    <div class="home">
        <x-header >实验检索</x-header>
        <x-content v-if="loading" style="height:100%;" :contentStyle="{alignItems:'center',justifyContent:'center',height:'100%'}">
            <bounce-loading></bounce-loading>
        </x-content>
        <x-content v-else  :contentStyle="{display:'block',width:'calc(100% - 32px)',padding:'16px'}">
            <div class="search_warp">
                <el-form label-width="70px" v-model="formItem">
                    <el-row class="list-control_warp" :gutter="10">
                        <el-col :span="5">
                           <el-form-item  label="全文检索">
							              <el-input  placeholder="请输入检索词" alwaysShowClear clearable v-model="formItem.fullTextSearch" />
                           </el-form-item>
                        </el-col>
                       <el-col :span="5">
                           <el-form-item  label="实验名称">
							              <el-input  placeholder="请输入实验名称" alwaysShowClear clearable v-model="formItem.searchText" />
                           </el-form-item>
                        </el-col>
                        <el-col :span="5">
                           <el-form-item  label="实验编号">
							              <el-input  placeholder="请输入实验编号" alwaysShowClear clearable v-model="formItem.experimentId" />
                           </el-form-item>
                        </el-col>
                             <el-col :span="5">
                           <el-form-item  label="所属项目">
                              <el-select  filterable   alwaysShowClear clearable v-model="formItem.groupId" placeholder="请选择项目">
                                <el-option :value="item.id" :label="!hasRole([SYSTEM_ROLE.MANAGER])?item.groupName+(item.archivingStatus&&item.archivingStatus==1?'(已归档)':''):item.groupName" v-for="(item,index) in projectList" :key="item.id"></el-option>
                              </el-select>
                           </el-form-item>
                        </el-col>
                        <el-col :span="4" v-if="!hasRole([SYSTEM_ROLE.MANAGER])">
                           <el-form-item  label="实验状态">
                            <el-select alwaysShowClear clearable v-model="formItem.status" style="width:100%;"  placeholder="请选择实验状态">
                              <el-option :value="item.value" :label="item.label" v-for="(item,index) in stateList" :key="index"></el-option>
                            </el-select>
                           </el-form-item>
                        </el-col>
                        <el-col :md="7" :xl="6"  v-if="!hasRole([SYSTEM_ROLE.MANAGER])" >
                           <el-form-item  label="实验类别">
							              <el-checkbox-group v-model="formItem.share" :min="1" @change="searchExperiment">
                                <el-checkbox label="1" >我的实验</el-checkbox>
                                <el-checkbox label="2" >分享给我的实验</el-checkbox>
                            </el-checkbox-group>
                           </el-form-item>
                        </el-col>
                        <el-col :md="9" :xl="7" >
                           <el-form-item  label="创建时间">
							                  <el-date-picker style="border:1px solod"
                                v-model="formItem.createDate"
                                type="daterange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                :picker-options="pickerOptions">
                                </el-date-picker>
                           </el-form-item>
                        </el-col>
                    <el-col  :md="4" >
                        <el-button   size="medium" type="primary"  @click="searchExperiment" icon="el-icon-search">
                             检索
                        </el-button>
                        <el-button   size="medium" type="info"  @click="clearSearch" icon="el-icon-refresh">
                            重置
                        </el-button>
                    </el-col>
                      <el-col :md="4" :xl="3">
                        <div class="sort_set" >
                          <el-select placeholder=""  v-model="sort" @change="searchExperiment"  style="width:50%;">
                            <el-option
                                v-for="item in sortOption"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                          </el-select>
                          <span style="cursor: pointer;margin-left:5px" @click="changeSortType" v-html="sortTypeHtml"></span>
                        </div>
                      </el-col>
                    </el-row>
                </el-form>
			      </div>
            <x-card>

                <el-table
                    :data="experimentList"
                    stripe
                    ref="reviewExperiemnt"
                    style="width: 100%">
                    <el-table-column
                        prop="experiment_id"
                        label="实验编号">
                        <template slot-scope="scope">
                              <el-tooltip content="查看实验详情" placement="bottom">
                            <el-link style='color:#409EFF' @click="$router.push('/manager/experiment/detail/'+scope.row.uuid+'/4')" :underline="false">{{scope.row.experiment_id}}</el-link>
                              </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="subject"
                        label="实验名称">
                    </el-table-column>

                    <el-table-column
                        label="所属项目">
                        <template slot-scope="scope">
                               <span class="">{{experimentGroupIdNameMap[scope.row.group_id]?experimentGroupIdNameMap[scope.row.group_id]:'未分类实验'}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        width="250"
                        label="属性">
                        <template slot-scope="scope">
                            <div style="display: flex; flex-direction: column;">
                                <span class="">创建时间：{{scope.row.create_time}}</span>
                                <span class="" v-if="scope.row.update_time">修改时间：{{scope.row.update_time}}</span>
                                <span class="">创建人：{{scope.row.user.name}}</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        width="100"
                        label="实验状态">
                        <template slot-scope="scope">
                            <span class="group_operate">{{scope.row.status=='0'?"待复核":scope.row.status=='1'?'复核通过':scope.row.status=='2'?'复核拒绝':scope.row.status=='3'?'待提交':scope.row.status=='4'?'已归档':''}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                    v-if="!hasRole([SYSTEM_ROLE.MANAGER])"
                        width="100"
                        label="操作">
                        <template slot-scope="scope" >
                          <action-panel :experimentObj="scope.row" :isOnlyCopy="true"></action-panel>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="page-warp">
                    <el-pagination
                        @current-change="searchExperiment"
                        :hide-on-single-page="total<pageSize"
                        background
                        layout="total,prev, pager, next"
                        :total="total"
                        :page-size="pageSize"
                        :current-page.sync="page"
                    ></el-pagination>
                </div>
            </x-card>
        </x-content>
    </div>
</template>
<script>
  import {GetMyProjectList} from "@/service/user"
  import {GetShareList, List, SearchExperiment} from "@/service/experiment";
  import Form from '../../components/base/ui/form/Form.vue';
  export default {
  components: { Form},
        name:'ExperimentSearch',
        data(){
            return {
                loading:false,
                page:1,
                total:0,
                pageSize:10,
                experimentList:[],
                share:"0",
                groupId:"",
                formItem:{
                  searchText:'',
                  createDate:'',
                  experimentId:'',
                  groupId:'',
                  fullTextSearch:'',
                  status:'',
                  share:['1', '2'],
                },
                shareJson:'',
                experimentGroupIdNameMap:{},
                userIdNameMap:null,
                startDate:'',
                endDate:'',
                pickerOptions: {
                  disabledDate(time) {
                    return time.getTime() > Date.now();
                  }
                },
                stateList:[
                    {
                        label:'待提交',
                        value:'3'
                    },
                        {
                        label:'待复核',
                        value:'0'
                    },    {
                    label:'复核通过',
                    value:'1'
                  },    {
                    label:'复核拒绝',
                    value:'2'
                  },
                ],
                sortOption: [{
                  value: 'create_time',
                  label: '创建时间'
                }, {
                  value: 'update_time',
                  label: '修改时间'
                }, {
                  value: 'experiment_id',
                  label: '实验编号'
                }],
                sortTypeHtml:'降序排序<i class="el-icon-sort-down"></i>',
                sortType:'desc',
                sort:'create_time',
            }
        },
        created(){
           this.searchExperiment();
           this.getMyProjectList();
        },
        methods:{
          changeSortType(){
            if(this.sortType=='desc'){
              this.sortType = 'asc';
              this.sortTypeHtml = '升序排序<i class="el-icon-sort-up"></i>'
              this.searchExperiment();
              return
            }
            if(this.sortType=='asc'){
              this.sortType = 'desc';
              this.sortTypeHtml = '降序排序<i class="el-icon-sort-down"></i>'
              this.searchExperiment();
              return
            }
          },
            clearSearch(){
              this.formItem.fullTextSearch='';
              this.formItem.searchText='';
              this.formItem.experimentId='';
              this.formItem.groupId='';
              this.formItem.status='';
              this.shareJson='';
              this.formItem.createDate='';
              this.formItem.share=['1', '2'];
              this.searchExperiment();
            },
            searchExperiment(){
                this.loading = true;
                if (this.formItem.createDate&&this.formItem.createDate.length>0){
                  this.startDate=this.formItem.createDate[0];
                  this.endDate=this.formItem.createDate[1];
                }else {
                  this.startDate='';
                  this.endDate='';
                }
                this.shareJson= this.formItem.share.join(',');
                SearchExperiment(
                    "",
                    this.formItem.fullTextSearch,
                    this.formItem.searchText,
                    this.formItem.experimentId,
                    this.formItem.groupId,
                    this.formItem.status,
                    this.shareJson,
                    this.startDate,
                    this.endDate,
                    this.sort,
                    this.sortType,
                    this.page,
                    this.pageSize,
                  ).then(e => {
                    let data = this.APIParse(e);
                    this.experimentList = data?.hits.map(item => {
                      return item._source;
                    });
                    this.total = data.total || 0;
                  }).catch(e => {
                    this.$message.error(e.message)
                  }).finally(e => {
                    this.loading = false;
                  })

            },
            getMyProjectList(){
              GetMyProjectList().then(e => {
                let data = this.APIParse(e);
                this.projectList = data;
                data.forEach(item => {
                  this.$set(this.experimentGroupIdNameMap,item.id,item.groupName)
                })
              })
            },
        }
    }
</script>
<style lang="scss" scoped>
    .group_operate+.group_operate{
        margin-left: 15px;
    }
    .Width-100{width: 100%}
    .home{width: 100%;height: 100%;overflow: auto;}
    .search_warp{width:100%;margin: 16px 0px;}
</style>